import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import GlobalLayout from '../layouts/globalLayout';
import * as styles from './rentals.module.css';
import EmailSubscribeForm from '../components/EmailSubscribeForm/emailSubscribeForm';

const imagesQuery = graphql`{
    headerPic: file(relativePath: {eq: "rentals/banner.jpg"}) {
      childImageSharp {
        gatsbyImageData(transformOptions: {cropFocus: CENTER}, layout: FULL_WIDTH)
      }
    },
    decorPic: file(relativePath: {eq: "rentals/Decor.jpeg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, width: 300, height: 300)
        }
    },
    furniturePic: file(relativePath: {eq: "rentals/Furniture.png"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, width: 300, height: 300)
        }
    },
    rugsPic: file(relativePath: {eq: "rentals/Rugs.png"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, width: 300, height: 300)
        }
    },
    tableTopPic: file(relativePath: {eq: "rentals/TableTop.jpg"}) {
        childImageSharp {
            gatsbyImageData(transformOptions: {cropFocus: CENTER}, width: 300, height: 300)
        }
    },
    allShopifyProduct(
        filter: {tags: {eq: "rent"}}
        limit: 4
    ) {
        edges {
            node {
                shopifyId
                handle
                featuredImage {
                    altText
                    gatsbyImageData(width: 200, layout: CONSTRAINED)
                }
            }
        }
    }
}`;

export default function Rentals() {
    const images = useStaticQuery(imagesQuery);
    const { allShopifyProduct } = images;

    return (
        <GlobalLayout>
            <GatsbyImage alt="macrame arch runway" image={images.headerPic.childImageSharp.gatsbyImageData} className={styles.headerImage} />
            <div className={styles.pageContent}>
                <div className={styles.buttonContainer}>
                    <Link to="/rentals/items#decor" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.decorPic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>decor</h1>
                    </Link>
                    <p className={styles.textCard}>
                        Making Magic for Southern California Events
                    </p>
                    <Link to="/rentals/items#furniture" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.furniturePic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>furniture</h1>
                    </Link>
                    <Link to="/rentals/items#rugs" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.rugsPic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>rugs</h1>
                    </Link>
                    <p className={styles.textCardSmall}>
                        Want to incorporate vintage magic into your special event?<br/>Add items to your wishlist & send it our way so we can make that magic happen
                    </p>
                    <Link to="/rentals/items#table-top" className={styles.card}>
                        <GatsbyImage className={styles.cardPicture} image={images.tableTopPic.childImageSharp.gatsbyImageData} />
                        <h1 className={styles.cardText}>table top</h1>
                    </Link>
                </div>
                <div className={styles.spacer}/>
                <div className={styles.favContainer}>
                    <div className={styles.favHeader}>
                        A FEW OF OUR FAVORITE PIECES
                    </div>
                    <div className={styles.favItemContainer}>
                        {allShopifyProduct.edges.map(({
                            node
                        }) => {
                            const { handle, featuredImage } = node;

                            return (
                                <Link key={handle} to={`/rentals/items/${handle}`} className={styles.favItemLink}>
                                    <GatsbyImage className={styles.favItemPic} image={featuredImage.gatsbyImageData} />
                                </Link>
                            )
                        })}
                    </div>
                </div>
                <div className={styles.emailFormContainer}>
                    <div className={styles.emailBackground}>
                        <div className={styles.emailHeader}>
                            stay in the know!
                        </div>
                        <div className={styles.emailSubHeader}>
                            BE NOTIFIED WHEN WE ADD NEW PIECES AND COLLECTIONS
                        </div>
                        <EmailSubscribeForm />
                    </div>                   
                </div>
            </div>
        </GlobalLayout>
    )
}